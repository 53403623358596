import { getBrandedClassString } from '@/components/Elements/Button';

export default {
  methods: {
    getQuestionDialogData({ question: text }) {
      return {
        text,
        buttons: [
          {
            title: this.$t('yes'),
            class: getBrandedClassString({ primary: true }, 'mr-3'),
            handler: () => null,
          },
          {
            title: this.$t('cancel'),
            class: getBrandedClassString({ secondary: true }),
            default: true,
          },
        ],
      };
    },
  },
};

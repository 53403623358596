<template lang="pug">
Fragment
  router-view(
    v-if="$route.params.slug"
    :filter="filter"
    :search="search"
    :useCaseMap="useCaseMap"
    :themes="themes"
  )
  .template-chooser-collection-page(v-else)
    transition-group(name="fade" mode="out-in")
      Banner.themes-banner(
        v-if="!isFilterActive && !search && $route.name === 'themes-collection'"
        key="themes-banner"
        :title="$t('templateChooser.banner.whatAreThemesTitle')"
        :description="$t('templateChooser.banner.whatAreThemesDescription')"
        @readMoreClick="openLink($t('templateChooser.banner.whatAreThemesReadMore'))"
        @watchAVideoClick="openLink('https://www.youtube.com/watch?v=GOVEkIeub50&list=PLQ4G56q46BrcXDId8TWAsuZaFNmHk_LQg&index=4')"
      )
        template(slot="image")
          img(src="@/assets/admin/img/what-are-themes.png" style="min-width: 20rem")
      Skeleton(v-if="$apollo.loading" key="skeleton" header)
      .template-chooser-collection-page-content(v-else :class="collectionClassNames" key="content")
        ListEmptyState(
          v-if="hasEmptyData"
          :image="hasEmptyData.image"
          :title="$t(hasEmptyData.title)"
          :description="$t(hasEmptyData.description)"
          v-on="hasEmptyData.navigation"
        )
        CollectionPreviews(
          v-else
          v-for="{ theme, templates } in filteredThemes"
          :key="theme._id || theme.name"
          :name="theme.name"
          :templates="templates"
          :themeKit="getThemeKit(theme)"
          :is-archive-enabled="isUserThemes"
          :is-title-editable="isUserThemes"
          :useCaseMap="useCaseMap"
          :to="getListingRoute(theme)"
          @renameCollection="updateCollectionName($event, theme)"
          @archive="showArchiveDialog(theme)"
          @track="track"
          @navigateWithFilter="$emit('navigateWithFilter', $event)"
        )
</template>

<script>
  import { Fragment } from 'vue-frag';
  import { mapState } from 'vuex';
  import GET_CHOOSER_FILTERED_TEMPLATES from '@/graphql/GetChooserFilteredTemplates.gql';
  import RENAME_CUSTOM_THEME_NAME from '@/graphql/RenameCustomThemeName.gql';
  import ARCHIVE_THEME from '@/graphql/ArchiveCustomTheme.gql';
  import childRouteMixin from '../mixins/childRoute';
  import questionDialogMixin from '../mixins/question-dialog';
  import filterHelperMixin from '../mixins/filterHelper';

  export default {
    components: {
      Fragment,
      ListEmptyState: () => import('@/components/TemplateChooser/components/ListEmptyState.vue'),
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
      CollectionPreviews: () =>
        import('@/components/TemplateChooser/components/CollectionPreviews.vue'),
      Banner: () => import('@/components/TemplateChooser/components/Banner.vue'),
    },
    mixins: [childRouteMixin, questionDialogMixin, filterHelperMixin],
    data: () => ({ templates: {} }),
    computed: {
      ...mapState(['accountType']),
      isThemesRoute() {
        return this.$route.name.includes('themes-collection');
      },
      hasEmptyData() {
        const hasResults = !!this.getPossibleTemplates()?.length;

        if (this.$apollo.loading || hasResults) return null;

        if (this.isUserThemes && !this.search && !this.validThemesList.length) {
          return {
            title: 'customTheme.chooser.block.empty.title',
            description: 'customTheme.chooser.block.empty.description',
            image: require('@/components/Elements/Monks/svgs/sad.svg'),
            navigation: { navigateTo: this.navigateToThemes },
          };
        }
        return {
          title: 'templateChooser.emptyStateBaseTitle',
          image: require('@/assets/admin/svg/monk_magnifying.svg'),
          navigation: {},
        };
      },
      isUserThemes() {
        return this.$route.name.includes('your-themes');
      },
      isSeasonal() {
        return this.$route.name.includes('seasonal');
      },
      collectionClassNames() {
        return {
          'template-chooser-collection-page-content--hover': this.isUserThemes,
        };
      },
      themesList() {
        const key = this.isUserThemes ? 'user' : this.isSeasonal ? 'seasons' : 'base';
        return this.themes[key] || [];
      },
      baseThemes() {
        const base = this.themes?.base || [];
        return base.filter(({ _id }) => !!_id);
      },
      baseThemeIds() {
        return this.baseThemes.map(({ _id }) => _id);
      },
      validThemesList() {
        if (this.isUserThemes) {
          return this.themesList.filter(({ sourceTheme }) =>
            this.baseThemeIds.includes(sourceTheme),
          );
        }
        return this.themesList;
      },
      filteredThemes() {
        return this.validThemesList
          .map((theme) => ({
            theme,
            templates: this.getTemplates(theme),
          }))
          .filter(({ templates }) => templates.length);
      },
      filterKey() {
        if (this.isThemesRoute) return 'themeKit';
        if (this.isSeasonal) return 'category';
        return null;
      },
      filterValue() {
        if (this.isThemesRoute) {
          return this.themesList.map((theme) => theme?.sourceTheme ?? theme._id);
        }
        return this.themes?.seasons?.map?.((s) => s.id) ?? [];
      },
      isFilterActive() {
        return Object.values(this.filter)?.flat?.()?.length;
      },
      trackComponentName() {
        if (this.isUserThemes) return 'Your themes';
        if (this.isSeasonal) return 'Seasonal templates';
        return 'Themes';
      },
    },
    apollo: {
      templates: {
        query: GET_CHOOSER_FILTERED_TEMPLATES,
        variables() {
          const filter = { ...this.filter, search: this.search };
          if (this.filterKey) {
            filter[this.filterKey] = this.filterValue;
          }
          return { filter, type: this.accountType };
        },
        result() {
          this.$emit('setLoading', false);
        },
      },
    },
    methods: {
      openLink(url) {
        window.open(url, '_blank').focus();
      },
      getListingRoute(theme) {
        return {
          name: this.$route.name.replace('collection', 'templates'),
          params: { slug: theme.slug },
        };
      },
      navigateToThemes() {
        this.$router.push({
          name: 'themes-collection',
          params: { userId: this.$route.params.userId },
        });
      },
      async updateCollectionName(name, theme) {
        const { _id } = theme;
        this.track({
          setting: 'rename',
          change: name,
          options: theme.name,
        });
        await this.$apollo
          .mutate({
            mutation: RENAME_CUSTOM_THEME_NAME,
            variables: { _id, name },
          })
          .then(() => {
            theme.name = name;
            this.$emit('renameSuccess');
          });
      },
      showArchiveDialog({ _id, name }) {
        const question = this.$t('templateChooser.themeArchiveQuestion');
        const dialogConfig = this.getQuestionDialogData({ question });
        dialogConfig.buttons[0].handler = () => this.archiveTheme(_id, name);
        dialogConfig.buttons[1].handler = () => {
          this.$modal.hide('dialog');
          this.track({
            setting: 'theme_archive',
            change: 'no',
            options: name,
          });
        };

        this.$modal.show('dialog', dialogConfig);
      },
      async archiveTheme(id, name) {
        const {
          data: {
            archiveCustomTheme: { success },
          },
        } = await this.$apollo.mutate({
          mutation: ARCHIVE_THEME,
          variables: {
            themeId: id,
          },
        });
        this.$modal.hide('dialog');
        if (success) {
          this.track({
            setting: 'theme_archive',
            change: 'yes',
            options: name,
          });
          this.$emit('refreshData');
        } else {
          this.track({
            setting: 'theme_archive',
            change: 'failed',
            options: name,
          });
        }
      },
      getPossibleTemplates() {
        return this.themesList.map((theme) => this.getTemplates(theme, false)).flat();
      },
      getTemplates(theme, cut = 4) {
        let possible;

        if (this.isSeasonal) {
          possible = this.templates?.byCategory?.[theme?.id] || [];
        } else if (theme) {
          const key = theme?.sourceTheme || theme?._id || theme;
          possible = this.templates?.byTheme?.[key] || [];
        }

        if (this.search) {
          possible = this.templates?.searchResults
            ?.map?.(({ _id: relevantId }) => possible.find(({ _id }) => _id === relevantId))
            ?.filter?.((v) => !!v);
        }

        return cut ? (possible || []).slice(0, cut) : possible || [];
      },
      getThemeKit(theme) {
        if (!theme?.themeKit) return null;
        const themeKit = { ...theme.themeKit };
        themeKit.id = themeKit?.id || theme._id;
        themeKit.source = theme?.sourceTheme;
        return themeKit;
      },
      track(event) {
        this.$emit('trackActivity', {
          ...event,
          component: this.trackComponentName,
        });
      },
    },
  };
</script>

<style lang="sass">
  .themes-banner
    margin-bottom: 2.5rem

  .template-chooser-collection-page
    .template-chooser-search-term
      padding-left: 1rem
    &-content
      .collection-previews
        padding: 1.25rem 1rem
        border-radius: 16px
        margin-bottom: 1.5rem

      &--hover
        .collection-previews
          transition: background-color 250ms
          &:hover
            background-color: #F7F7F8

            .collection-previews-heading-action
              display: flex
        .collection-previews-heading--title
          width: calc(100% - 180px)
          max-width: 700px
          display: block
          overflow: hidden

          .title-editing
            width: 100%
            display: block

            .title-holder .title, textarea
              width: 100%
              text-align: start
              display: block

    .template-chooser-page-loading-skeleton
      .loading-row
        margin-bottom: 4.5rem !important
</style>
